import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import Loader from 'components/Elements/Loader/Loader';
import NotFound from 'components/NotFound';
import useRosieUser from '../hooks/useRosieUser';
import UpdateRosieUserForm from '../components/UpdateRosieUserForm';

const PageContainer = styled.div`
  padding: 1rem 2rem;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export default function UserDetails() {
  const { id } = useParams();
  const { value, isDoneLoading, onUpdate } = useRosieUser(id || '');

  if (!isDoneLoading) {
    return <Loader theme="dark" isVisible fullscreen />;
  }

  if (value === null) {
    return <NotFound />;
  }

  return (
    <PageContainer>
      <UpdateRosieUserForm defaultValue={value} onUpdate={onUpdate} />
    </PageContainer>
  );
}
