export const SEALS_SCLA_YEARS = [
  {
    label: '2019',
    value: '2019',
  },

  {
    label: '2020',
    value: '2020',
  },

  {
    label: '2021',
    value: '2021',
  },

  {
    label: '2022',
    value: '2022',
  },

  {
    label: '2023',
    value: '2023',
  },
];

export const SEALS_MOTIVATION = [
  {
    label: 'To be a bad-ass SEAL',
    value: 'seal',
  },

  {
    label: 'To be a better leader',
    value: 'leader',
  },

  {
    label: 'To be a better developer',
    value: 'developer',
  },

  {
    label: 'To be better at business development',
    value: 'business',
  },

  {
    label: 'To learn Artificial Intelligence',
    value: 'ai',
  },
];

export const SEALS_CREED = [
  {
    label: 'I want to improve on leading by example.',
    value: 'I want to improve on leading by example.',
  },
  {
    label:
      'I want to improve on being honest and not lying, cheating, or stealing.',
    value:
      'I want to improve on being honest and not lying, cheating, or stealing.',
  },
  {
    label: 'I want to improve on thinking outside the box.',
    value: 'I want to improve on thinking outside the box.',
  },
  {
    label: 'I want to improve on helping my neighbor and community.',
    value: 'I want to improve on helping my neighbor and community.',
  },
  {
    label:
      'I want to improve on understanding that leadership is action, not just a position.',
    value:
      'I want to improve on understanding that leadership is action, not just a position.',
  },
  {
    label:
      'I want to improve on being a role model to young males in my community.',
    value:
      'I want to improve on being a role model to young males in my community.',
  },
  {
    label:
      'I want to improve on my belief that nothing is hard, just a lot of work.',
    value:
      'I want to improve on my belief that nothing is hard, just a lot of work.',
  },
  {
    label:
      'I want to improve on striving to know myself better and seeking self-improvement.',
    value:
      'I want to improve on striving to know myself better and seeking self-improvement.',
  },
  {
    label: 'I want to improve on the habit of doing more than asked for.',
    value: 'I want to improve on the habit of doing more than asked for.',
  },
  {
    label:
      'I want to improve on striving to strengthen my mind, body, and soul.',
    value:
      'I want to improve on striving to strengthen my mind, body, and soul.',
  },
];

export const SEALS_HELP_PROCESS = [
  {
    label: 'I ask for help',
    value: 'ask-for-help',
  },

  {
    label: 'I research using Google.',
    value: 'research',
  },

  {
    label: 'I keep trying until I figure it out.',
    value: 'keep-trying',
  },

  {
    label: "A lot of time I don't know what to do.",
    value: 'idk',
  },
];

export const SEALS_DOING_MORE_INCENTIVE = [
  {
    label: 'Money',
    value: 'money',
  },

  {
    label: 'Mentors',
    value: 'mentorship',
  },

  {
    label: 'Career',
    value: 'career',
  },

  {
    label: 'Family',
    value: 'family',
  },

  {
    label: 'Fame/Status',
    value: 'fame',
  },

  {
    label: 'Knowledge',
    value: 'knowledge',
  },

  {
    label: 'My Faith/Religion',
    value: 'faith',
  },
];
