import React from 'react';
import { Tag } from 'rsuite';
import styled from 'styled-components';
import {
  TAG_COLOR_BLUE,
  TAG_COLOR_GREEN,
  TAG_COLOR_RED,
} from '../../../constants/colors';
import { RosieUserType } from '../types';

export interface IProfileTypeTagProps {
  type: RosieUserType;
  size: 'lg' | 'md' | 'sm' | undefined;
}

const getColor = (type: RosieUserType) => {
  if (type === 'admin' || type === 'super-admin') return TAG_COLOR_GREEN;

  if (type === 'user') return TAG_COLOR_BLUE;

  return TAG_COLOR_RED;
};

const CustomTag = styled(Tag)`
  text-transform: lowercase;
  color: #fff;
  background-color: ${props => getColor(props.type)};
`;

export function RosieUserTypeTag(props: IProfileTypeTagProps) {
  const { type, size } = props;

  return <CustomTag type={type} size={size}>{type}</CustomTag>;
}
