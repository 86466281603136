import React from 'react';
import styled from 'styled-components';

interface ITextCellProps {
  rowData: any;
  dataKey: string;
}

const Text = styled.p``;

function BooleanCell({ dataKey, rowData }: ITextCellProps) {
  const dataKeysArray = dataKey.split('.');


  if (dataKeysArray.length === 1) {
    return <Text>{rowData[dataKeysArray[0]] || ''}</Text>;
  }

  if (rowData[dataKeysArray[0]]) {
    const nestedValue = dataKeysArray.reduce(
      (acc, key) => acc[key],
      rowData,
    );
    return <Text>{nestedValue ? 'Yes':'No'}</Text>;
  }

  return <Text>deleted user</Text>
}

export default BooleanCell;
