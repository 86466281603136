/* eslint-disable react/jsx-props-no-spreading */
import FormControlLabel from 'components/Form/Control/FormControlLabel';
import { FormFieldSize } from 'components/Form/CustomForm';
import React, { forwardRef } from 'react';
import { DatePicker, Form } from 'rsuite';
import styled from 'styled-components';

const FormGroup = styled(Form.Group)<{ ref: any; size: FormFieldSize }>`
    flex: 1 0 100%;
  @media (min-width: 992px){
    flex: 0 1 ${props => (props.size === 'sm' ? '50%' : '100%')};
  }
`;

const ControlWrapper = styled.div`
  margin: 0.5rem;
`;

/**
 * Renders a date picker
 */
const DatePickerFormField = forwardRef((props: any, ref: any) => {
  const {
    size,
    name,
    helpMessage,
    label,
    required
  } = props;


  return (
    <FormGroup ref={ref} size={size}>
       <FormControlLabel required={required || false}>{label} </FormControlLabel>
      <ControlWrapper>
        <Form.Control
          format="MM/dd/yyyy"
          placeholder="mm/dd/yyyy"
          style={{ width: '100%' }}
          name={name}
          accepter={DatePicker}
          oneTap
          aria-hidden="true"
        />
        {helpMessage ? <Form.HelpText>{helpMessage}</Form.HelpText> : null}
      </ControlWrapper>
    </FormGroup>
  );
});

export default DatePickerFormField;
