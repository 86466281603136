import React from 'react';
import { Tag } from 'rsuite';
import styled from 'styled-components';
import {
  COLOR_GOLD,
  COLOR_GREEN,
  COLOR_RED,
} from '../../../../constants/colors';

interface ISclaAppSectionsCompleteTagProps {
  count: number;
  size: 'lg' | 'md' | 'sm' | undefined;
}

const getColor = (count: number) => {
  if (count >= 6) return COLOR_GREEN;

  if (count < 6 && count >= 1) return COLOR_GOLD;

  return COLOR_RED;
};

const CustomTag = styled(Tag)`
  text-transform: lowercase;
  color: ${props =>
    getColor(props.type) === COLOR_GOLD ? 'black' : '#fff'};
  background-color: ${props => getColor(props.type)};
`;

export default function SclaAppSectionsCompleteTag(
  props: ISclaAppSectionsCompleteTagProps,
) {
  const { count, size } = props;

  return (
    <CustomTag type={count} size={size}>
      {count}/6 complete
    </CustomTag>
  );
}
