import Button from 'components/Elements/Button/Button';
import React from 'react';
import { Form } from 'rsuite';
import styled from 'styled-components';
import CheckboxFormField from './Input/Base/CheckboxFormField';
import TextFormField from './Input/Base/TextFormField';
import SelectFormField from './Input/Base/SelectFormField';
import DatePickerFormField from './Input/Other/DatePickerFormField';
import PhoneNumberFormField from './Input/Base/PhoneNumberFormField';
import TextAreaFormField from './Input/Base/TextareaFormField';
import AutoCompleteFormField from './Input/Base/AutoCompleteFormField';
import MultiSelectFormField from './Input/Base/MultiSelectFormField';
import ActivitiesFields from './Input/Multi/ActivitiesFields';

export type FormFieldSize = 'sm' | 'lg';

export type FormField = {
  name: string;
  label: string;
  type: string;
  data: any;
  size: FormFieldSize;
  required?: boolean;
};

export interface IFormFieldMap {
  [key: string]: React.ComponentType<any>; // Index signature for string keys
}

interface ICustomFormProps {
  fields: FormField[];
  isLoading: boolean;
  model: any;
  formValue: any;
  isDisabled: boolean;
  submitLabel?: string;
  hasChanges: boolean;
  submitBtnSize?: ButtonSize;
  onCancel?: () => void;
  onChange: (val: any) => void;
  onSubmit: () => any;
}

const CustomFormContainer = styled(Form)`
  display: flex;
  gap: 0px;
  flex-wrap: wrap;
  width: 100%;
`;

const SubmitButtonContainer = styled.div`
  flex: 1 1 100%;
  justify-content: flex-end;
  display: flex;
  gap: 16px;
  margin: 0.5rem 0.5rem 0 0.5rem;
`;

const FormFieldMap: IFormFieldMap = {
  text: TextFormField,
  textarea: TextAreaFormField,
  email: TextFormField,
  password: TextFormField,
  checkbox: CheckboxFormField,
  select: SelectFormField,
  multi_select: MultiSelectFormField,
  date: DatePickerFormField,
  phoneNumber: PhoneNumberFormField,
  autoComplete: AutoCompleteFormField,
  scla_activities: ActivitiesFields,
};

export function CustomFormField({
  isDisabled,
  field,
  formValue,
  onChange,
}: {
  field: FormField;
  isDisabled: boolean;
  formValue: any;
  onChange: (val: any) => void;
}) {
  if (FormFieldMap[field.type]) {
    const FieldMap = FormFieldMap[field.type];

    if (
      field.type === 'text' ||
      field.type === 'email' ||
      field.type === 'password' ||
      field.type === 'phoneNumber'
    ) {
      return (
        <FieldMap
          disabled={isDisabled}
          size={field.size}
          type={field.type}
          name={field.name}
          label={field.label}
          required={field.required || false}
        />
      );
    }

    if (field.type === 'textarea') {
      return (
        <FieldMap
          disabled={isDisabled}
          size={field.size}
          type={field.type}
          name={field.name}
          label={field.label}
          data={field.data}
          required={field.required || false}
        />
      );
    }

    if (field.type === 'autoComplete') {
      return (
        <FieldMap
          disabled={isDisabled}
          size={field.size}
          type={field.type}
          name={field.name}
          label={field.label}
          data={field.data}
          formValue={formValue}
          onChange={onChange}
          required={field.required || false}
        />
      );
    }

    if (field.type === 'select') {
      return (
        <FieldMap
          disabled={isDisabled}
          size={field.size}
          type={field.type}
          name={field.name}
          label={field.label}
          data={field.data}
          formValue={formValue}
          required={field.required || false}
        />
      );
    }

    if (field.type === 'multi_select') {
      return (
        <FieldMap
          disabled={isDisabled}
          size={field.size}
          type={field.type}
          name={field.name}
          label={field.label}
          data={field.data}
          formValue={formValue}
          required={field.required || false}
        />
      );
    }

    if (field.type === 'date') {
      return (
        <FieldMap
          disabled={isDisabled}
          size={field.size}
          type={field.type}
          name={field.name}
          label={field.label}
          required={field.required || false}
        />
      );
    }

    if (field.type === 'scla_activities') {
      return (
        <FieldMap
          disabled={isDisabled}
          size={field.size}
          type={field.type}
          name={field.name}
          label={field.label}
          required={field.required || false}
        />
      );
    }

    return (
      <FieldMap
        size="lg"
        disabled={isDisabled}
        type={field.type}
        name={field.name}
        label={field.label}
        required={field.required || false}
      />
    );
  }

  return <div />;
}

export default function CustomForm(props: ICustomFormProps) {
  const {
    submitBtnSize,
    hasChanges,
    isDisabled,
    fields,
    isLoading,
    model,
    formValue,
    submitLabel,
    onCancel,
    onChange,
    onSubmit,
  } = props;

  return (
    <CustomFormContainer
      fluid
      model={model}
      formValue={formValue}
      onChange={onChange}
    >
      {fields.map(field => (
        <CustomFormField
          formValue={formValue}
          onChange={(val: any) => {
            const payload = { ...formValue };
            payload[field.name] = val;
            onChange(payload);
          }}
          isDisabled={isDisabled}
          key={field.name}
          field={field}
        />
      ))}

      <SubmitButtonContainer>
        {onCancel && (
          <Button
            size="md"
            type="button"
            isLoading={false}
            onClick={onCancel}
            color="grey"
            disabled={isLoading}
          >
            <p> Cancel</p>
          </Button>
        )}

        {submitLabel && (
          <Button
            size={submitBtnSize || 'md'}
            type="submit"
            isLoading={isLoading}
            onClick={onSubmit}
            disabled={isLoading || !hasChanges}
          >
            <p> {submitLabel} </p>
          </Button>
        )}
      </SubmitButtonContainer>
    </CustomFormContainer>
  );
}

CustomForm.defaultProps = {
  onCancel: undefined,
  submitBtnSize: undefined,
  submitLabel: undefined,
};
