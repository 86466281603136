import React from 'react';
import styled from 'styled-components';
import ContentContainer from 'components/Layout/ContentContainer';
import useAppNavigation from 'hooks/useAppNavigation';
import { Button } from 'rsuite';
import RosieUserTable from '../components/RosieUserTable';
import useRosieUserTableSearchParam from '../hooks/useRosieUserTableSearchParam';

const PageContainer = styled.div`
  padding: 0.5rem 1rem;
  width: 100%;
`;

/**
 * Makes use of PageContainer styled component to set the appropriate size of
 * table container.
 *
 * @returns
 */
export default function UserList() {
  const { navToCreateRosieUser } = useAppNavigation();

  const {
    isDone,
    page,
    limit,
    sortColumn,
    sortType,
    userType,
    onPageChange,
    onChangeLimit,
    onSortColumn,
    search,
    filters,
    showFilters,
    setShowFilters,
  } = useRosieUserTableSearchParam();

  return (
    <PageContainer>
      <ContentContainer
        renderHeaderActions={() => (
          <Button
            appearance="primary"
            color={showFilters ? 'red' : 'green'}
            size="sm"
            onClick={() => setShowFilters(!showFilters)}
          >
            {showFilters ? 'Hide Filters' : 'Show Filters'}
          </Button>
        )}
        title="Rosie User Accounts"
        isMenuLoading={false}
        menuOptions={[
          {
            label: 'Create new user',
            onClick: navToCreateRosieUser,
          },
          {
            label: 'Export to excel',
            onClick: () => {},
          },
        ]}
      >
        <RosieUserTable
          filters={filters}
          showFilters={showFilters}
          userType={userType}
          isDone={isDone}
          page={page}
          limit={limit}
          sortColumn={sortColumn}
          sortType={sortType}
          search={search}
          onPageChange={onPageChange}
          onChangeLimit={onChangeLimit}
          onSortColumn={onSortColumn}
        />
      </ContentContainer>
    </PageContainer>
  );
}
