// eslint-disable-next-line import/prefer-default-export
export const SCLA_APP_STATUS = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'not-started',
    label: 'Not Started',
  },
  {
    value: 'in-progress',
    label: 'In Progress',
  },
  {
    value: 'complete',
    label: 'Complete',
  },
];

export const SCLA_PROGRAM_YEAR = [
  {
    value: '2016',
    label: '2016',
  },
  {
    value: '2017',
    label: '2017',
  },
  {
    value: '2018',
    label: '2018',
  },
  {
    value: '2019',
    label: '2019',
  },
  {
    value: '2020',
    label: '2020',
  },
  {
    value: '2021',
    label: '2021',
  },
  {
    value: '2022',
    label: '2022',
  },
  {
    value: '2023',
    label: '2023',
  },
  {
    value: '2024',
    label: '2024',
  },
];

export const PICKER_MARITAL_STATUS = [
  {
    value: 'married',
    label: 'Married',
    role: '',
  },
  {
    value: 'Separated',
    label: 'Separated',
    role: '',
  },
  {
    value: 'divorced',
    label: 'Divorced',
    role: '',
  },
  {
    value: 'widowed',
    label: 'Widowed',
    role: '',
  },
  {
    value: 'single',
    label: 'Single',
    role: '',
  },
];

export const PICKER_EMPLOYMENT_STATUS = [
  {
    value: 'employed',
    label: 'Employed',
    role: '',
  },
  {
    value: 'unemployed',
    label: 'Unemployed',
    role: '',
  },
  {
    value: 'retired',
    label: 'Retired',
    role: '',
  },
  {
    value: 'deceased',
    label: 'Deceased',
    role: '',
  },
  {
    value: 'no-contact',
    label: 'No Contact',
    role: '',
  },
];

export const PICKER_SCLA_SALARY = [
  {
    label: '$0-$20,000',
    value: '$0-$20,000',
  },

  {
    label: '$21,000-$40,000',
    value: '$21,000-$40,000',
  },

  {
    label: '$41,000-$80,000',
    value: '$41,000-$80,000',
  },

  {
    label: '$81,000+',
    value: '$81,000+',
  },
];

export const PICKER_SCLA_CAREER = [
  {
    label: 'Technology',
    value: 'technology',
  },

  {
    label: 'Design',
    value: 'design',
  },

  {
    label: 'Business',
    value: 'business',
  },

  {
    label: 'Entrepreneurship',
    value: 'entrepreneurship',
  },
];

export const PICKER_SCLA_CHARACTER = [
  { label: 'Knowledgeable', value: 'knowledgeable' },
  { label: 'Curious', value: 'curious' },
  { label: 'Non-Conformist', value: 'non-conformist' },
  { label: 'Compassionate', value: 'compassionate' },
  { label: 'Kind', value: 'kind' },
  { label: 'Determined', value: 'determined' },
  { label: 'Resilient', value: 'resilient' },
  { label: 'Confident', value: 'confident' },
  { label: 'People-Pleaser', value: 'people-pleaser' },
  { label: 'Over-Achiever', value: 'over-achiever' },
  { label: 'Perfectionist', value: 'perfectionist' },
  { label: 'Popular', value: 'popular' },
  { label: 'Leader', value: 'leader' },
];

export const SCLA_APP_TYPE = [
  {
    value: 'scla-applicant',
    label: 'scla-applicant',
  },
  {
    value: 'txter',
    label: 'txter',
  },
];
