
import { Button, Modal } from "rsuite";
import React from "react";
import ButtonSpinner from "components/Elements/Button/ButtonSpinner";

interface IDeleteRosieUserModal {
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  onDelete: () => void;
}

/**
 * Presents modal to confirm the deletion of a user
 * @param props
 * @returns
 */
export default function DeleteRosieUserModal(props: IDeleteRosieUserModal) {
  const { open, isLoading, onClose, onDelete } = props;

  return (
    <Modal
      backdrop="static"
      role="alertdialog"
      open={open}
      onClose={onClose}
      size="xs"
    >
      <Modal.Body>
        <p>Are you sure you want to delete this user?</p>
      </Modal.Body>
      <Modal.Footer>
        <ButtonSpinner
          onClick={onDelete}
          color="red"
          type="button"
          appearance="primary"
          label="Delete User"
          isLoading={isLoading}
          disabled={isLoading}
          size="sm"
        />
        <Button
          disabled={isLoading}
          size="sm"
          onClick={onClose}
          appearance="subtle"
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
