
// eslint-disable-next-line import/prefer-default-export
export const TXTER_USER_TYPE = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'txter',
    label: 'TXTer',
  },
  {
    value: 'alumni',
    label: 'Alumni',
  },
];
