import { makeGetRequest } from 'lib/axios';
import { PaginateResult, PaginationRequestPayload } from 'types';
import { RosieUser } from '../types';

/**
 * Make API request for rosie users
 * @returns
 */
const getRosieUsers = async (
  payload: PaginationRequestPayload,
  userType: string
): Promise<PaginateResult<RosieUser>> => {

  const { page, limit, sortColumn, sortType, search, } = payload;
  const url = `/user/pagination?page=${page}&limit=${limit}&sortColumn=${sortColumn}&sortType=${sortType}&search=${search}&userType=${userType}`;
  const response = await makeGetRequest(url);
  return response.data.payload;
};

export default getRosieUsers;
