import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import useNavigationLinks, {
  DropdownLink,
} from 'hooks/useNavigationLinks';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { COLOR_BLACK, COLOR_LIGHT_BLUE } from 'constants/colors';
import { getSideMenuStatus } from 'store/slices/sideMenuSlice';
import ParagraphText from 'components/Elements/ParagraphText/ParagraphText';
import useLogout from 'hooks/useLogout';
import { useAppSelector } from '../../store/hooks';

export interface SideNavLinkProps {
  link: DropdownLink; // link to display
  toggleLinkDropdown: (link: DropdownLink) => void; // open link dropdown
}

const SideNavContainer = styled.div<{ isOpen: boolean }>`
  padding-top: 80px;
  position: fixed;
  left: ${props => (props.isOpen ? '0px' : '-256px')};
  top: 0;
  background-color: ${COLOR_BLACK};
  width: 256px;
  height: 100%;
  transition: left ease-in 300ms;
  z-index: 108;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
`;

const ScrollContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1.25rem;
  position: relative;
`;

const MenuWrapper = styled.div`
  margin: 0 -1.25rem -1.25rem;
  padding-bottom: 75px;

  ul {
    margin: 0;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
    user-select: none;

    li {
      margin: 0.5px 0;
    }
  }
`;

const CaretIcon = styled.div`
  margin-left: auto;
  font-size: 21px;
`;

const DropdownList = styled.ul`
  list-style: none;

  li a {
    padding-left: 50px !important;
  }
`;

const TriggerText = styled(ParagraphText)`
  font-size: 16px;
  color: #fff;
  margin: 0;
`;

const LinkTrigger = styled.button`
  width: 100%;
  display: flex;
  line-height: 1.5rem;
  padding: 0.675rem 0.75rem 0.675rem 1.25rem;
  color: #fff;
  text-decoration: none !important;
  align-items: center;
  background-color: transparent;

  &:focus,
  &:hover {
    color: ${COLOR_LIGHT_BLUE};

    ${TriggerText} {
      color: ${COLOR_LIGHT_BLUE};
    }
  }

  svg {
    font-size: 1rem;
    margin: 0 0.5rem 0 0;
    flex: 0 0 1rem;
    text-align: center;
  }
`;

const InternalLink = styled(NavLink)`
  width: 100%;
  display: flex;
  line-height: 1.5rem;
  padding: 0.675rem 0.75rem 0.675rem 2.5rem;
  color: #fff;
  text-decoration: none !important;
  align-items: center;
  background-color: 'transparent';

  &:focus,
  &:hover {
    color: ${COLOR_LIGHT_BLUE};
    ${TriggerText} {
      color: ${COLOR_LIGHT_BLUE};
    }
  }

  span {
    font-size: 16px !important;
  }

  svg {
    font-size: 1rem;
    margin: 0 0.5rem 0 0;
    flex: 0 0 1rem;
    text-align: center;
  }

  &.active {
    background-color: ${COLOR_LIGHT_BLUE};

    &:focus,
    &:hover {
      color: #fff;
      ${TriggerText} {
        color: #fff;
      }
    }
  }
`;

const ExternalLink = styled.a`
  width: 100%;
  display: flex;
  line-height: 1.5rem;
  padding: 0.675rem 0.75rem 0.675rem 1.25rem;
  color: #fff;
  text-decoration: none !important;
  align-items: center;
  background-color: transparent;

  &:focus,
  &:hover {
    color: ${COLOR_LIGHT_BLUE};
    ${TriggerText} {
      color: ${COLOR_LIGHT_BLUE};
    }
  }

  span {
    font-size: 16px !important;
  }

  svg {
    font-size: 1rem;
    margin: 0 0.5rem 0 0;
    flex: 0 0 1rem;
    text-align: center;
  }
`;

function SideNavLink({ link, toggleLinkDropdown }: SideNavLinkProps) {
  return (
    <li>
      <LinkTrigger type="button" onClick={() => toggleLinkDropdown(link)}>
        <div> {link.icon}</div>
        <TriggerText>{link.title}</TriggerText>
        <CaretIcon>
          {link.active ? (
            <FontAwesomeIcon icon={faChevronRight} />
          ) : (
            <FontAwesomeIcon icon={faChevronLeft} />
          )}
        </CaretIcon>
      </LinkTrigger>

      <DropdownList>
        {link.active
          ? link.children.map(sub => (
              <div key={sub.title}>
                {sub.type === 'internal' ? (
                  <InternalLink to={sub.to} end>
                    <div>{sub.icon}</div>

                    <TriggerText>{sub.title}</TriggerText>
                  </InternalLink>
                ) : (
                  <ExternalLink
                    href={sub.to}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div>{sub.icon}</div>

                    <TriggerText>{sub.title}</TriggerText>
                  </ExternalLink>
                )}
              </div>
            ))
          : null}
      </DropdownList>
    </li>
  );
}

/**
 * Side menu that contains all the page links
 * Links are shown based on the user account type
 * @param param0
 * @returns
 */
function SideNav() {
  const { links, toggleLinkDropdown } = useNavigationLinks();

  const isOpen = useAppSelector(getSideMenuStatus);

  const { logout } = useLogout();

  return (
    <SideNavContainer isOpen={isOpen}>
      <ScrollContainer>
        <MenuWrapper>
          <ul>
            {links.map(link => (
              <SideNavLink
                key={link.title}
                link={link}
                toggleLinkDropdown={toggleLinkDropdown}
              />
            ))}

            <div>
              <LinkTrigger type="button" onClick={logout}>
                <div>
                  <FontAwesomeIcon icon={faSignOutAlt} />
                </div>
                <TriggerText>Sign Out</TriggerText>
              </LinkTrigger>
            </div>
          </ul>
        </MenuWrapper>
      </ScrollContainer>
    </SideNavContainer>
  );
}

export default SideNav;
