import React from 'react';
import styled from 'styled-components';
import { RosieUserTypeTag } from './RosieUserTypeTag';


interface IRosieUserTypeCellProps {
  rowData: any;
  dataKey: string;
}

const CellContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
function RosieUserTypeCell({ dataKey, rowData }: IRosieUserTypeCellProps) {

  return (
    <CellContainer>
      <RosieUserTypeTag size="md" type={rowData[dataKey]} />
    </CellContainer>
  );
}

export default RosieUserTypeCell;
