import { makeGetRequest } from 'lib/axios';
import { RosieUser } from 'features/rosie';

const sendWelcomeEmail = async (id: string): Promise<RosieUser> => {
  const url = `/user/${id}/send-welcome-email`;
  const response = await makeGetRequest(url);
  return response.data.payload;
};

export default sendWelcomeEmail;
