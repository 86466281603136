import { makeGetRequest }  from 'lib/axios';
import { UserSession } from 'features/rosie';

/**
 * Make API request for rosie user session
 * @returns 
 */
 const getUserSession = async (): Promise<UserSession> => {
  const url = `/user`;
  const response = await makeGetRequest(url);
  return response.data.payload;
};

export default getUserSession;