import React from 'react';
import { Tag } from 'rsuite';
import styled from 'styled-components';
import {
  TAG_COLOR_BLUE,
  TAG_COLOR_GREEN,
  TAG_COLOR_ORANGE,
} from '../../../constants/colors';

export type TxterProfileType = 'alumni' | 'txter';

export interface IUserTypeTagProps {
  type: any;
  size: 'lg' | 'md' | 'sm' | undefined;
}

const getColor = (type: any) => {
  console.log(type);
  if (type === 'super-admin') return TAG_COLOR_GREEN;

  if (type === 'user') return TAG_COLOR_BLUE;

  if (type === 'guest') return TAG_COLOR_ORANGE;

  if (type === 'scla-app-reader') return TAG_COLOR_ORANGE;

  return TAG_COLOR_BLUE;
};

const ProfileTag = styled(Tag)`
  color: #fff;
  background-color: ${props => getColor(props.type)};
`;

export function UserTypeTag(props: IUserTypeTagProps) {
  const { type, size } = props;
  return <ProfileTag type={type} size={size}>{type}</ProfileTag>;
}
