import { useEffect, useState } from 'react';
import serverErrorHandler from 'services/serverError.service';
import { RosieUser } from '../types';
import getRosieUser from '../api/getRosieUser';

export default function useRosieUser(id: string) {
  const [value, setValue] = useState<RosieUser | null>(null);
  const [isDoneLoading, setIsDoneLoading] = useState(false);

  useEffect(() => {
    let mounted = true;

    async function loadDataAsync() {
      try {
        setIsDoneLoading(false);
        const user = await getRosieUser(id);
        setValue(user);
        setIsDoneLoading(true);
      } catch (e: any) {
        setIsDoneLoading(true);
        serverErrorHandler(e);
      }
    }

    if (mounted) loadDataAsync();

    return () => {
      mounted = false;
    };
  }, [id]);

  const onUpdate = (updatedValues: RosieUser) => {
    setValue(updatedValues);
  };
  
  return {
    value,
    isDoneLoading,
    onUpdate,
  };
}
