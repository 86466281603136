import React from 'react';
import styled from 'styled-components';
import { TxterTypeTag } from '../TxterTypeTag';

interface ITxterTypeCellProps {
  rowData: any;
  dataKey: string;
}

const CellContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
function TxterTypeCell({ dataKey, rowData }: ITxterTypeCellProps) {
  return (
    <CellContainer>
      <TxterTypeTag size="md" type={rowData[dataKey]} />
    </CellContainer>
  );
}

export default TxterTypeCell;
