import React from 'react';
import { Tag } from 'rsuite';
import styled from 'styled-components';
import {
  COLOR_GOLD,
  COLOR_GREEN,
  COLOR_RED,
} from '../../../../constants/colors';
import { SclaAppStatus } from '../../types';

interface ISclaAppStatusTagProps {
  type: SclaAppStatus;
  size: 'lg' | 'md' | 'sm' | undefined;
}

const getColor = (type: SclaAppStatus) => {
  if (type === 'complete') return COLOR_GREEN;

  if (type === 'in-progress') return COLOR_GOLD;

  return COLOR_RED;
};

const CustomTag = styled(Tag)`
  text-transform: lowercase;
  color: ${props =>
    getColor(props.type) === COLOR_GOLD ? 'black' : '#fff'};
  background-color: ${props => getColor(props.type)};
`;

export default function SclaAppStatusTag(props: ISclaAppStatusTagProps) {
  const { type, size } = props;

  return (
    <CustomTag type={type} size={size}>
      {type}
    </CustomTag>
  );
}
