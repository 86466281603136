import React from 'react';
import styled from 'styled-components';

interface ITextCellProps {
  rowData: any;
  dataKey: string;
}

const Text = styled.p``;
/**
 * Renders a table cell with the text rendered
 * @param param0
 * @returns
 */
function TextCell({ dataKey, rowData }: ITextCellProps) {
  const dataKeysArray = dataKey.split('.');

  if (dataKeysArray.length === 1) {
    return <Text>{rowData[dataKeysArray[0]] || ''}</Text>;
  }

  if (rowData[dataKeysArray[0]]) {
    const nestedValue = dataKeysArray.reduce(
      (acc, key) => acc[key],
      rowData,
    );
    return <Text>{nestedValue}</Text>;
  }

  return <Text>deleted user</Text>
}

export default TextCell;
