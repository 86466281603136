/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Popover, Table, Whisper } from 'rsuite';
import React from 'react';

export type TableMenuItem = {
  label: string;
  onSelect: (id: string) => void;
};
// get Table elements
const { Cell } = Table;

/**
 * Renders a Table cell
 *
 * @param param0
 * @returns
 */
export function MenuCell({ menuItems, rowData, ...props }: any) {
  /**
   *
   * @param props
   * @param ref
   * @returns
   */
  const renderMenu = (props: any, ref: any) => {
    const { left, top } = props;

    /**
     * Close the popover when user selects
     * an option on the menu
     * @param eventKey
     */
    const handleSelect = () => {
      props.onClose();
    };

    return (
      <Popover
        ref={ref}
        className={props.className}
        style={{ left, top }}
        full
      >
        <Dropdown.Menu onSelect={handleSelect}>
          {menuItems.map((item: TableMenuItem, i: number) => (
            <Dropdown.Item
              key={i}
              eventKey={i}
              onSelect={() => {
                item.onSelect(rowData._id);
              }}
            >
              {item.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Popover>
    );
  };

  return (
    <Cell {...props}>
      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <Whisper placement="auto" trigger="click" speaker={renderMenu}>
          <div
            style={{
              display: 'flex',
              cursor: 'pointer',
              flex: '0 0 50px',
              justifyContent: 'center',
              paddingTop: '5px',
              paddingBottom: '5px',
            }}
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </div>
        </Whisper>
      </div>
    </Cell>
  );
}
