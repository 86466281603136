import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ProfileList from './UserList';
import CreateUser from './CreateUser';
import UserDetails from './UserDetails';

export default function RosieRoutes() {

  return (
    <Routes>
      <Route path="users/" element={<ProfileList />} />
      <Route path="users/details/:id" element={<UserDetails />} />
      <Route path="users/new" element={<CreateUser />} />
      <Route path="" element={<Navigate to="users" />} />
      <Route path="*" element={<Navigate to="users" />} />
    </Routes>
  );
}

