/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import { CheckPicker, Form } from 'rsuite';
import styled from 'styled-components';
import { FormFieldSize } from 'components/Form/CustomForm';
import FormControlLabel from '../../Control/FormControlLabel';

const FormGroup = styled(Form.Group)<{ ref: any; size: FormFieldSize }>`
  flex: 1 0 100%;
  @media (min-width: 992px) {
    flex: 0 1 ${props => (props.size === 'sm' ? '50%' : '100%')};
  }
`;

const ControlWrapper = styled.div`
  margin: 0.5rem;
`;

/**
 * Renders a select picker
 */
const MultiSelectFormField = forwardRef((props: any, ref: any) => {
  const { required, size, name, helpMessage, label, data } = props;

  const showRequired: boolean = required || false;

  return (
    <FormGroup size={size} ref={ref}>
      <FormControlLabel required={showRequired}>{label} </FormControlLabel>
      <ControlWrapper>
        <Form.Control
          style={{ width: '100%', height: 38 }}
          accepter={CheckPicker}
          name={name}
          data={data.picker}
          placement="bottomStart"
          searchable={data ? data.searchable || false : false}
          cleanable={false}
        />
        {helpMessage ? <Form.HelpText>{helpMessage}</Form.HelpText> : null}
      </ControlWrapper>
    </FormGroup>
  );
});

export default MultiSelectFormField;

MultiSelectFormField.defaultProps = {
  helpMessage: undefined,
};
