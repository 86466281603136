import { makePutRequest } from 'lib/axios';
import { RosieUser } from 'features/rosie';
import { CreateRosieUserDto } from 'dtos/rosieUser.dto';

/**
 * Make API request to create update rosie user
 * @returns
 */
const updateRosieUser = async (
  id: string,
  payload: CreateRosieUserDto,
): Promise<RosieUser> => {
  const url = `/user/${id}`;
  const response = await makePutRequest(url, payload);
  return response.data.payload;
};

export default updateRosieUser;
