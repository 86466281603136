import React from 'react';
import styled from 'styled-components';
import ContentContainer from 'components/Layout/ContentContainer';
import CustomForm from 'components/Form/CustomForm';
import useCreateRosieUser from '../hooks/useCreateRosieUser';

const PageContainer = styled.div`
  padding: 1rem 2rem;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export default function CreateUser() {
  const {
    hasChanges,
    value,
    fields,
    isLoading,
    model,
    onChange,
    onSubmit,
  } = useCreateRosieUser();
  return (
    <PageContainer>
      <ContentContainer title="Create New Rosie User">
        <CustomForm
          hasChanges={hasChanges}
          fields={fields}
          isLoading={isLoading}
          model={model}
          formValue={value}
          isDisabled={false}
          onChange={onChange}
          onSubmit={onSubmit}
          submitLabel="Create User"
        />
      </ContentContainer>
    </PageContainer>
  );
}
