import { makePostRequest } from 'lib/axios';
import { RosieUser } from 'features/rosie';
import { CreateRosieUserDto } from 'dtos/rosieUser.dto';

/**
 * Make API request to create new rosie user
 * @returns
 */
const createRosieUser = async (
  payload: CreateRosieUserDto,
): Promise<RosieUser> => {
  const url = `/user`;
  const response = await makePostRequest(url, payload);
  return response.data.payload;
};

export default createRosieUser;
