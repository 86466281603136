import { makeGetRequest } from 'lib/axios';
import { RosieUser } from 'features/rosie';

/**
 * Make API request to fetch user profile
 * @returns
 */
const getRosieUser = async (id: string): Promise<RosieUser> => {
  const url = `/user/${id}`;
  const response = await makeGetRequest(url);
  return response.data.payload;
};

export default getRosieUser;
