/* eslint-disable react/jsx-props-no-spreading */
import RosieUserTypeCell from 'features/rosie/components/RosieUserTypeCell';
import React from 'react';
import { Table } from 'rsuite';
import MyTxtUserTypeCell from 'features/txters/components/table/TxterTypeCell';
import SclaAppStatusCell from 'features/scla/components/table/SclaAppStatusCell';
import SclaAppTypeCell from 'features/scla/components/table/SclaAppTypeCell';
import SclaReturnerAppCell from 'features/scla/components/table/SclaReturnerAppCell';
import TextCell from './TextCell';
import IsActiveCell from './IsActiveCell';
import NameCell from './NameCell';
import DateCell from './DateCell';
import BooleanCell from './BooleanCell';

export type CustomTableCells =
  | 'date'
  | 'name'
  | 'text'
  | 'rosieUserTypeTag'
  | 'isActiveTag'
  | 'myTxtUserTypeTag'
  | 'sclaAppStatus'
  | 'myTxtUserType'
  | 'sclaAppType'
  | 'sclaSectionStatus' | 'boolean';

export interface TableCellMap {
  [key: string]: React.ComponentType<any>; // Index signature for string keys
}

const DataTableCellMap: TableCellMap = {
  text: TextCell,
  date: DateCell,
  rosieUserTypeTag: RosieUserTypeCell,
  isActiveTag: IsActiveCell,
  myTxtUserTypeTag: MyTxtUserTypeCell,
  name: NameCell,
  sclaAppStatus: SclaAppStatusCell,
  sclaAppType: SclaAppTypeCell,
  myTxtUserType: MyTxtUserTypeCell,
  sclaSectionStatus: SclaReturnerAppCell,
  boolean: BooleanCell
};

// get Table elements
const { Cell } = Table;

function CustomCell({ type, rowData, dataKey, ...props }: any) {
  if (DataTableCellMap[type]) {
    const CellMap = DataTableCellMap[type];
    return (
      <Cell dataKey={dataKey} {...props}>
        <CellMap rowData={rowData} dataKey={dataKey} />
      </Cell>
    );
  }

  return <TextCell rowData={rowData} dataKey={dataKey} />;
}

export default CustomCell;
