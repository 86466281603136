import React from 'react';
import { faEllipsisV, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from 'components/Elements/Header/Header';
import { COLOR_PRIMARY } from 'constants/colors';
import { Button } from 'rsuite';
import styled from 'styled-components';
import DropdownMenu, {
  DropdownItem,
} from 'components/Elements/Dropdown/Dropdown';

interface IDefaultContainerProps {
  title: string; // title of container
  menuOptions?: DropdownItem[]; // menu options to be rendered.
  isMenuLoading?: boolean; // loader state for menu async methods
  isMenuDisabled?: boolean;
  children: any; // elements to be rendered inside the body of container
  className?: string;
  renderHeaderActions?: () => React.ReactNode;
}

const StyledContainer = styled.div`
  width: 100%;
  @media (min-width: 1024px) {
    max-width: 100%;
  }
`;

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 6px;
  border: none;
  padding-left: 0px;
  padding-right: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  padding: 0.65rem 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
  border-top: 4px solid ${COLOR_PRIMARY};
  background-color: #fff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;

const ContainerContent = styled.div`
  padding: 1.25rem 1.5rem;
  flex: 1;
`;

const HeaderTitle = styled(Header)`
  font-size: 19px;
  line-height: 18px;
  text-transform: capitalize;
`;

const HeaderActionsContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 16px;
`;
/**
 * Renders a container with head and body section. The head
 * contains the container title and menu options (if provided).
 * The body contains the children passed to the component
 * @param props
 * @returns
 */
export default function ContentContainer(props: IDefaultContainerProps) {
  const {
    renderHeaderActions,
    isMenuDisabled,
    title,
    children,
    menuOptions,
    className,
    isMenuLoading = false,
  } = props;

  return (
    <StyledContainer className={className}>
      <Wrapper className="table-wrapper">
        <ContainerHeader className="table-header">
          <HeaderTitle>{title}</HeaderTitle>
          <HeaderActionsContainer>
            {renderHeaderActions && renderHeaderActions()}
          </HeaderActionsContainer>
          {menuOptions ? (
            <DropdownMenu
              disabled={isMenuDisabled}
              toggleElement={
                <Button>
                  {isMenuLoading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    <FontAwesomeIcon icon={faEllipsisV} />
                  )}
                </Button>
              }
              items={menuOptions}
            />
          ) : null}
        </ContainerHeader>
        <ContainerContent className="table-container">
          {children}
        </ContainerContent>
      </Wrapper>
    </StyledContainer>
  );
}

ContentContainer.defaultProps = {
  menuOptions: undefined,
  isMenuLoading: undefined,
  isMenuDisabled: undefined,
  className: '',
  renderHeaderActions: undefined,
};
