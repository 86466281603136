import React from 'react';
import { Tag } from 'rsuite';
import styled from 'styled-components';
import {
  TAG_COLOR_BLUE,
  TAG_COLOR_GREEN,
  TAG_COLOR_RED,
} from '../../../constants/colors';
import { TxterUserType } from '../types';

export interface ITxterTypeTagProps {
  type: TxterUserType;
  size: 'lg' | 'md' | 'sm' | undefined;
}

const getColor = (type: TxterUserType) => {
  if (type === 'txter') return TAG_COLOR_GREEN;

  if (type === 'alumni') return TAG_COLOR_BLUE;

  return TAG_COLOR_RED;
};

const CustomTag = styled(Tag)`
  text-transform: lowercase;
  color: #fff;
  background-color: ${props => getColor(props.type)};
`;

export function TxterTypeTag(props: ITxterTypeTagProps) {
  const { type, size } = props;

  return (
    <CustomTag type={type} size={size}>
      {type}
    </CustomTag>
  );
}
