import AppProvider from 'providers/app';
import AppRoutes from 'routes';
import React from 'react';

console.log("v3");
function App() {
  // render routes
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
}

export default App;
