/* eslint-disable react/jsx-props-no-spreading */
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  COLOR_GREEN,
  COLOR_PRIMARY,
  COLOR_RED,
  COLOR_GREY,
} from 'constants/colors';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

export interface IProps {
  children: JSX.Element;
}

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  // variant?: keyof typeof variants;
  // size?: keyof typeof sizes;
  isLoading?: boolean;
  width?: number;
  color?: ButtonColor;
  size: ButtonSize;
} & IconProps;

const isDarkColor = (color: any) => {
  console.log(color);
  if (color === 'red') return true;
  if (color === 'grey') return true;
  if (color === 'blue') return true;
  return false;
};
const getButtonColor = (c: ButtonColor) => {
  if (c === 'grey') return COLOR_GREY;

  if (c === 'red') return COLOR_RED;

  if (c === 'green') return COLOR_GREEN;

  return COLOR_PRIMARY;
};

const StyledButton = styled.button<ButtonProps>`
  border-radius: 6px;
  background-color: ${props =>
    props.color ? getButtonColor(props.color) : COLOR_PRIMARY};
  font-size: 12px;
  line-height: 20px;
  padding: 2px 8px;

  ${({ size }: { size: ButtonSize }) =>
    size === 'sm' &&
    `
    font-size: 14px;
    line-height: 20px;
    padding: 5px 10px;
    `}

  ${({ size }: { size: ButtonSize }) =>
    size === 'md' &&
    `
    font-size: 14px;
    line-height: 20px;
    padding: 8px 12px;
    `}

  ${({ size }: { size: ButtonSize }) =>
    size === 'lg' &&
    `
      font-size: 16px;
      line-height: 22px;
      padding: 10px 16px;
      `}
  
  ${({ size }: { size: ButtonSize }) =>
    size === 'full' &&
    `
      width: 100% !important;
      font-size: 16px;
      line-height: 22px;
      padding: 10px 16px;
      `}
  

  * {
    color: ${props => (isDarkColor(props.color) ? '#fff' : 'black')};
  }

  &[disabled] {
    opacity: 0.5;
  }
`;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      color = 'blue',
      type = 'button',
      isLoading = false,
      startIcon,
      endIcon,
      ...props
    },
    ref,
  ) => (
    <StyledButton color={color} ref={ref} type={type} {...props}>
      {isLoading && (
        <FontAwesomeIcon
          color="#fff"
          style={{
            paddingLeft: 4,
            paddingRight: 4,
            paddingTop: 1,
            paddingBottom: 1,
          }}
          spin
          icon={faSyncAlt}
        />
      )}
      {!isLoading && startIcon}
      {!isLoading && props.children}
      {!isLoading && endIcon}
    </StyledButton>
  ),
);

Button.defaultProps = {
  isLoading: undefined,
  width: undefined,
  color: undefined,
};
export default Button;
