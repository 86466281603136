/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import { Form, InputGroup } from 'rsuite';
import styled from 'styled-components';
import { FormFieldSize } from 'components/Form/CustomForm';
import FormControlLabel from '../../Control/FormControlLabel';

interface ITextFormField {
  required: boolean;
  size: FormFieldSize;
  disabled?: boolean;
  name: string; // name of the key to map to
  label: string; // field label
  type: 'text' | 'email' | 'number' | 'password';
  helpMessage?: string; // help message to display
  placeholder?: string; // field placeholder
  checkAsync?: boolean; // true if we need to perform async checks on form field
  className?: any; // custom class name for form field group
}

const FormGroup = styled(Form.Group)<{ ref: any; size: FormFieldSize }>`
  flex: 1 0 100%;
  @media (min-width: 992px) {
    flex: 0 1 ${props => (props.size === 'sm' ? '50%' : '100%')};
  }
`;

const ControlWrapper = styled.div`
  margin: 0.5rem;
`;
/**
 * Renders text from field
 */
const TextFormField = forwardRef((props: ITextFormField, ref: any) => {
  const {
    size,
    disabled,
    checkAsync = false,
    placeholder = '',
    name,
    helpMessage,
    label,
    required,
    ...rest
  } = props;

  return (
    <FormGroup ref={ref} size={size}>
      <FormControlLabel required={required || false}>
        {label}{' '}
      </FormControlLabel>
      <ControlWrapper>
        {checkAsync ? (
          <Form.Control
            disabled={disabled || false}
            checkAsync
            placeholder={placeholder}
            name={name}
            acceptor={InputGroup}
            {...rest}
          />
        ) : (
          <Form.Control
            disabled={disabled || false}
            placeholder={placeholder}
            name={name}
            acceptor={InputGroup}
            {...rest}
          />
        )}
        {helpMessage ? <Form.HelpText>{helpMessage}</Form.HelpText> : null}
      </ControlWrapper>
    </FormGroup>
  );
});

export default TextFormField;

TextFormField.defaultProps = {
  disabled: undefined,
  helpMessage: undefined,
  placeholder: undefined,
  checkAsync: undefined,
  className: undefined,
};
