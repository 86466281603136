import IsActiveTag from 'components/Elements/Tag/IsActiveTag';
import React from 'react';
import styled from 'styled-components';


interface IIsActiveCellProps {
  rowData: any;
  dataKey: string;
}

const CellContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
function IsActiveCell({ dataKey, rowData }: IIsActiveCellProps) {

  return (
    <CellContainer>
      <IsActiveTag size="md" isActive={rowData[dataKey]} />
    </CellContainer>
  );
}

export default IsActiveCell;
