/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import { Form, SelectPicker } from 'rsuite';
import styled from 'styled-components';
import { FormFieldSize } from 'components/Form/CustomForm';
import FormControlLabel from '../../Control/FormControlLabel';
import TextFormField from './TextFormField';

const FormGroup = styled(Form.Group)<{ ref: any; size: FormFieldSize }>`
  flex: 1 0 100%;
  @media (min-width: 992px) {
    flex: 0 1 ${props => (props.size === 'sm' ? '50%' : '100%')};
  }
`;

const ControlWrapper = styled.div`
  margin: 0.5rem;
`;

/**
 * Renders a select picker
 */
const SelectFormField = forwardRef((props: any, ref: any) => {
  const { required, formValue, size, name, helpMessage, label, data } = props;

  if (formValue && formValue[name].toLowerCase() === 'other') {
    return (
      <>
        <FormGroup size={size} ref={ref}>
          <FormControlLabel required={required || false}>
            {label}{' '}
          </FormControlLabel>
          <ControlWrapper>
            <Form.Control
              style={{ width: '100%', height: 38 }}
              accepter={SelectPicker}
              name={name}
              data={data.picker}
              placement="bottomStart"
              searchable={data ? data.searchable || false : false}
              cleanable={false}
            />
            {helpMessage ? (
              <Form.HelpText>{helpMessage}</Form.HelpText>
            ) : null}
          </ControlWrapper>
        </FormGroup>

        <TextFormField
          required={false}
          size={size}
          name={`${name}Other`}
          label={data.otherLabel}
          type="text"
        />
      </>
    );
  }
  return (
    <FormGroup size={size} ref={ref}>
      <FormControlLabel required={required || false}>
        {label}{' '}
      </FormControlLabel>
      <ControlWrapper>
        <Form.Control
          style={{ width: '100%', height: 38 }}
          accepter={SelectPicker}
          name={name}
          data={data.picker}
          placement="bottomStart"
          searchable={data ? data.searchable || false : false}
          cleanable={false}
        />
        {helpMessage ? <Form.HelpText>{helpMessage}</Form.HelpText> : null}
      </ControlWrapper>
    </FormGroup>
  );
});

export default SelectFormField;

SelectFormField.defaultProps = {
  helpMessage: undefined,
};
