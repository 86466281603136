import { useState } from 'react';
import { Schema } from 'rsuite';
import serverErrorHandler from 'services/serverError.service';
import _isEqual from 'lodash/isEqual';
import { getFormValidationStatus } from 'utils/form.util';
import useAppNavigation from 'hooks/useAppNavigation';
import { PICKER_ROSIE_USER_TYPE } from 'constants/picker';
import { FormField } from 'components/Form/CustomForm';
import { openSuccessToaster } from 'services/toast.service';
import createRosieUser from '../api/createRosieUser';

// Extract schema types for form validation
const { StringType } = Schema.Types;

/**
 * Define validation model for login form.
 * User must provide a valid email and password
 * in order to submit the form.
 *
 * rsuite(5.5.2): https://rsuitejs.com/components/form-validation/
 */
const model = Schema.Model({
  firstName: StringType().isRequired('Please enter a first name.'),
  lastName: StringType().isRequired('Please enter a first name.'),
  email: StringType()
    .isRequired('Please enter a valid email.')
    .isEmail('Please enter a valid email.'),
  type: StringType().isRequired('Please select a user type'),
});

const INIT_FORM = { email: '', firstName: '', lastName: '', type: '' };

const fields: FormField[] = [
  {
    name: 'firstName',
    type: 'text',
    label: 'First Name',
    size: 'sm',
    data: null,
  },
  {
    name: 'lastName',
    type: 'text',
    label: 'Last Name',
    size: 'sm',
    data: null,
  },
  {
    name: 'email',
    type: 'email',
    label: 'Email',
    size: 'sm',
    data: null,
  },
  {
    name: 'type',
    type: 'select',
    label: 'User Type',
    size: 'sm',
    data: {
      picker: PICKER_ROSIE_USER_TYPE.filter(item => item.value !== 'all'),
    },
  },
];

export default function useCreateRosieUser() {
  const [value, setValue] = useState<any>(INIT_FORM); // set default form values
  const [isLoading, setIsLoading] = useState(false); // flag for submission process
  const [hasChanges, setHasChanges] = useState(false);

  const { navToRosieUserList } = useAppNavigation();
  /**
   * Makes server request to validate user credentials.
   * Users with valid credentials are authenticated and
   * redirect to the authenticated routes
   */
  const onSubmit = async () => {
    try {
      // verify that form is valid
      if (getFormValidationStatus(model.check(value))) {
        setIsLoading(true);
        await createRosieUser(value);
        openSuccessToaster('User created.', 3000);
        setIsLoading(false);
        navToRosieUserList();
      }
    } catch (e) {
      // hide spinner
      setIsLoading(false);
      serverErrorHandler(e);
    }
  };

  const onChange = (newVal: any) => {
    setHasChanges(!_isEqual(newVal, value));
    setValue(newVal);
  };

  return {
    hasChanges,
    fields,
    value,
    isLoading,
    model,
    onSubmit,
    onChange,
  };
}
