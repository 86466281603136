import React, { useEffect, useState } from 'react';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { UserSession } from 'features/rosie';
import { dispatchSideMenuToggle } from 'store/dispatcher';
import ParagraphText from 'components/Elements/ParagraphText/ParagraphText';
import styled from 'styled-components';
import {
  COLOR_BLACK,
  COLOR_LIGHT_BLUE,
  COLOR_SECONDARY,
} from 'constants/colors';
import UserProfile from 'components/Cards/UserProfile';
import { getSideMenuStatus } from 'store/slices/sideMenuSlice';
import DropdownMenu, {
  DropdownItem,
} from 'components/Elements/Dropdown/Dropdown';
import useLogout from 'hooks/useLogout';
import { useAppSelector } from '../../store/hooks';

const StyledContainer = styled.div`
  display: flex;
  position: fixed !important;
  background-color: ${COLOR_BLACK};
  align-items: center;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  height: 4.75rem;
  padding: 1.25rem;
  margin: 0 auto;
  box-shadow: 0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1);
  z-index: 999;
  border-bottom: solid 1px #fff;
`;

const BrandContainer = styled.div`
  @media (min-width: 992px) {
    order: 1;
  }
`;

const BrandLink = styled(NavLink)`
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

const StyledDropdown = styled(DropdownMenu)`
  display: none;
  order: 2;
  margin-right: 10px;
  margin-left: auto;
  @media (min-width: 992px) {
    display: block;
  }
`;

interface BurgerProps {
  sideMenuIsOpen: boolean;
}

const Burger = styled.button<BurgerProps>`
  background-color: transparent;
  display: block;
  width: 35px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
  margin-right: 0;
  text-align: center;
  margin-top: 0px;
  order: 3;

  @media (min-width: 992px) {
    margin-right: 25px;
    margin-left: 0;
    order: 0;
  }

  svg {
    color: ${props => (props.sideMenuIsOpen ? COLOR_SECONDARY : '#fff')};
    width: 25px !important;
    height: 35px;
    margin: auto;
  }
`;

interface NavBarProps {
  user: UserSession;
}
/**
 * Renders navigation menu.
 * @param param0
 * @returns
 */
export default function NavBar({ user }: NavBarProps) {
  const sideMenuIsOpen = useAppSelector(getSideMenuStatus);
  const [links, setLinks] = useState<DropdownItem[]>([]);

  const { logout } = useLogout();

  useEffect(() => {
    const navBarLinks: DropdownItem[] = [
      {
        label: 'Logout',
        onClick: logout,
      },
    ];

    setLinks(navBarLinks);
  }, []);

  return (
    <StyledContainer>
      <BrandContainer>
        <BrandLink end to="/">
          <ParagraphText
            fontSize={24}
            marginRight={2}
            color={COLOR_LIGHT_BLUE}
          >
            TXT-
          </ParagraphText>
          <ParagraphText margin={0} fontSize={24} color="#fff">
            Rosie
          </ParagraphText>
        </BrandLink>
      </BrandContainer>

      <Burger
        sideMenuIsOpen={sideMenuIsOpen}
        onClick={dispatchSideMenuToggle}
      >
        <FontAwesomeIcon color="#fff" icon={faBars} />
      </Burger>

      <StyledDropdown
        toggleElement={<UserProfile user={user} />}
        items={links}
      />
    </StyledContainer>
  );
}
