import { Tag } from 'rsuite';
import styled from 'styled-components';
import React from 'react';


export type AppStatusTagProps = {
  isActive: boolean;
  size: 'lg' | 'md' | 'sm' | undefined;
};

const Container = styled(Tag)`
  text-transform: capitalize;
`;

export default function IsActiveTag(props: AppStatusTagProps) {
  const { isActive, size } = props;

  if (isActive) {
    return (
      <Container color="green" size={size}>
        True
      </Container>
    );
  }
  return (
    <Container color="red" size={size}>
      False
    </Container>
  );
}
