import { SclaAppStatus } from 'features/scla/types';
import { useNavigate } from 'react-router-dom';
import {
  getCreateEmailTemplatePath,
  getCreateRosieUserPath,
  getEmailTemplateDetailsPath,
  getEmailTemplateListPath,
  getMyTxtUserDetailsPath,
  getMyTxtUserListPath,
  getMyTxtUserStatsPath,
  getRosieUserDetailsPath,
  getRosieUserListPath,
  getSclaAppDetailsPath,
  getSclaAppListPath,
  getSclaAppStatsPath,
  getSclaInterestListPath,
  getSealsAppListPath,
  getSealsAppStatsPath,
  getSealsLegacyAppListPath,
  getTxterListPath,
  getTxterStatsPath,
} from 'utils/navigation';

export default function useAppNavigation() {
  const navigate = useNavigate();

  return {
    navToLogin: () => navigate(getRosieUserListPath()),
    // my-txt
    navToMyTxtUserList: () => navigate(getMyTxtUserListPath()),
    navToMyTxtUserStats: () => navigate(getMyTxtUserStatsPath()),
    navToMyTxtUserDetails: (id: string) =>
      navigate(getMyTxtUserDetailsPath(id)),

    // rosie
    navToRosieUserList: () => navigate(getRosieUserListPath()),
    navToRosieUserDetails: (id: string) =>
      navigate(getRosieUserDetailsPath(id)),
    navToCreateRosieUser: () => navigate(getCreateRosieUserPath()),
    // txters
    navToTxterList: () => navigate(getTxterListPath()),
    navToTxterStats: () => navigate(getTxterStatsPath()),
    // scla
    navToSclaAppStats: (year: string, ) => navigate(getSclaAppStatsPath(year)),
    navToSclaAppList: (
      page: number,
      limit: number,
      sortColumn: string,
      sortType: string,
      appStatus: SclaAppStatus,
      year: string,
      school: string,
      search?: string,
    ) => {
      if (search) {
        navigate(
          getSclaAppListPath(
            page,
            limit,
            sortColumn,
            sortType,
            appStatus,
            year,
            school,
            search,
          ),
        );
      } else {
        navigate(
          getSclaAppListPath(
            page,
            limit,
            sortColumn,
            sortType,
            appStatus,
            year,
            school,
            '',
          ),
        );
      }
    },
    navToSclaInterestsList: (
      page: number,
      limit: number,
      sortColumn: string,
      sortType: string,
      year: string,
      school: string,
      search?: string,
    ) => {
      if (search) {
        navigate(
          getSclaInterestListPath(
            page,
            limit,
            sortColumn,
            sortType,
            year,
            school,
            search,
          ),
        );
      } else {
        navigate(
          getSclaInterestListPath(
            page,
            limit,
            sortColumn,
            sortType,
            year,
            school,
            '',
          ),
        );
      }
    },
    navToSclaAppDetails: (id: string, appId: string) =>
      navigate(getSclaAppDetailsPath(id, appId)),

    navToSealsLegacyAppList: () => navigate(getSealsLegacyAppListPath()),
    navToSealsLegacyAppStats: () => navigate(getSealsAppStatsPath()),
    navToSealsAppList: () => navigate(getSealsAppListPath()),

    navToEmailTemplateList: () => navigate(getEmailTemplateListPath()),
    navToCreateEmailTemplate: () => navigate(getCreateEmailTemplatePath()),
    navToEmailTemplateDetails: (id: string) =>
      navigate(getEmailTemplateDetailsPath(id)),
  };
}
