import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/main.scss';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import { injectStore } from 'store/dispatcher';
import useWindowResizeObserver from 'hooks/useWindowResizeObserver';

type AppProviderProps = {
  children: React.ReactNode;
};

injectStore(store);

const description = ``;

export default function AppProvider({ children }: AppProviderProps) {
  useWindowResizeObserver();
  return <>
      <Helmet>
        <title>TXT Teens Exploring Technology </title>
        <meta name="description" content={description} />
      </Helmet>
      <Provider store={store}>
        <Router>{children}</Router>
      </Provider>
    </>
}
