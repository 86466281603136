/* eslint-disable react/jsx-props-no-spreading */
import { Pagination, Table } from 'rsuite';
import React from 'react';
import { DataTableColumn, DataTableMenuBtn } from 'types';
import SclaViewAppCell from 'features/scla/components/table/SclaViewAppCell';
import styled from 'styled-components';
import useWindowSize from 'hooks/useWindowSize';
import { MenuCell, TableMenuItem } from './Cells/MenuCell';
import CustomCell from './Cells/CustomCell';

export type SortType = 'desc' | 'asc';

// get Table elements
const { Column, HeaderCell } = Table;

type DataTableProps = {
  showFilters: boolean;
  menuBtn?: DataTableMenuBtn;
  menuItems?: TableMenuItem[];
  columns: DataTableColumn[];
  isLoading: boolean;
  sortColumn: string;
  sortType: SortType | undefined;
  limit: number;
  length: number;
  page: number;
  data: any[];
  onSortColumn: (newSortColumn: string, newSortType: any) => void;
  onPageChange: (dataKey: number) => void;
  onChangeLimit: (dataKey: number) => void;
};

const Container = styled.div`
  width: 100%;
`;

const getTableHeight = (windowHeight: number, isFiltersOpen: boolean) => {
  let height = 100;
  if (windowHeight >= 1400) height = 950;
  if (windowHeight < 1400 && windowHeight >= 1300) height = 850;
  if (windowHeight < 1300 && windowHeight >= 1200) height = 750;
  if (windowHeight < 1200 && windowHeight >= 1100) height = 650;
  if (windowHeight < 1100 && windowHeight >= 1000) height = 580;
  if (windowHeight < 1000 && windowHeight >= 900) height = 475;
  if (windowHeight < 900 && windowHeight >= 800) height = 375;
  if (windowHeight < 800 && windowHeight >= 750) height = 325;
  if (windowHeight < 750 && windowHeight >= 700) height = 285;
  if (windowHeight < 700 && windowHeight >= 650) height = 250;
  if (windowHeight < 650 && windowHeight >= 600) height = 200;

  if (!isFiltersOpen) height += 200;
  else height -= 30;

  return height;
};

export default function DataTable(props: DataTableProps) {
  const {
    menuBtn,
    menuItems,
    columns,
    isLoading,
    sortColumn,
    sortType,
    length,
    page,
    limit,
    data,
    showFilters,
    onSortColumn,
    onPageChange,
    onChangeLimit,
  } = props;

  const { height } = useWindowSize();

  return (
    <Container>
      <Table
        height={getTableHeight(height, showFilters)}
        // autoHeight
        // affixHorizontalScrollbar
        // affixHorizontalScrollbar={0}
        data={data}
        loading={isLoading}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={onSortColumn}
      >
        {menuItems ? (
          <Column flexGrow={0} width={65}>
            <HeaderCell> </HeaderCell>
            <MenuCell dataKey="_id" menuItems={menuItems} />
          </Column>
        ) : null}

        {menuBtn && (
          <Column flexGrow={0} width={100} resizable>
            <HeaderCell>View App</HeaderCell>
            <SclaViewAppCell dataKey="_id" menuBtn={menuBtn} />
          </Column>
        )}

        {columns.map((column: any) => {
          const { key, label, ...rest } = column;

          return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Column {...rest} key={key} resizable>
              <HeaderCell>{label}</HeaderCell>
              <CustomCell type={column.cell} dataKey={key} />
            </Column>
          );
        })}
      </Table>

      <Pagination
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        maxButtons={5}
        size="xs"
        layout={['total', '-', 'limit', '|', 'pager', 'skip']}
        total={length}
        limitOptions={[10, 20, 30, 50, 100, 200]}
        limit={limit}
        activePage={page}
        onChangePage={onPageChange}
        onChangeLimit={onChangeLimit}
      />
    </Container>
  );
}

DataTable.defaultProps = {
  menuItems: undefined,
  menuBtn: undefined,
};
