import Tooltip from 'components/Elements/Tooltip/Tooltip';
import DateFormat from 'enum/dateFormat';
import React from 'react';
import { momentDateFormat } from 'services/moment.service';

interface IDateCellProps {
  rowData: any;
  dataKey: string;
}

/**
 * Renders a table cell with the date rendered
 * @param param0
 * @returns
 */
function DateCell({ dataKey, rowData }: IDateCellProps) {
  const dataKeysArray = dataKey.split('.');
  if (dataKeysArray.length === 1) {
    return (
      <Tooltip
        msg={momentDateFormat(rowData[dataKey], DateFormat.LONG)}
        placement="top"
      >
        {momentDateFormat(rowData[dataKey], DateFormat.MEDIUM)}
      </Tooltip>
    );
  }
  const nestedValue = dataKeysArray.reduce(
    (acc, key) => acc[key],
    rowData,
  );
  return (
    <Tooltip
      msg={momentDateFormat(nestedValue, DateFormat.LONG)}
      placement="top"
    >
      {momentDateFormat(nestedValue, DateFormat.MEDIUM)}
    </Tooltip>
  );
}

export default DateCell;
