import  { useState, useEffect } from 'react';
import { DataTableColumn } from 'types';
import serverErrorHandler from 'services/serverError.service';
import { useSearchParams } from 'react-router-dom';
import { openSuccessToaster } from 'services/toast.service';
import getRosieUsersPagination from '../api/getRosieUsersPagination';
import { RosieUser } from '../types';
import deleteRosieUser from '../api/deleteRosieUser';
import sendWelcomeEmail from '../api/sendWelcomeEmail';


/**
 * All available columns that can shown
 * for RosieUser
 */
const defaultColumns: DataTableColumn[] = [
  {
    label: 'User Type',
    key: 'type',
    cell: 'rosieUserTypeTag',
    sortable: false,
    width: 140,
    flexGrow: 0,
  },
  {
    label: 'Is Active',
    key: 'isActive',
    cell: 'isActiveTag',
    sortable: false,
    width: 100,
    flexGrow: 0,
  },
  {
    label: 'First Name',
    key: 'firstName',
    cell: 'text',
    sortable: true,
    width: 150,
    flexGrow: 0,
  },

  {
    label: 'Last Name',
    key: 'lastName',
    cell: 'text',
    sortable: true,
    width: 150,
    flexGrow: 0,
  },

  {
    label: 'Email',
    key: 'email',
    cell: 'text',
    sortable: false,
    width: 300,
    flexGrow: 0,
  },

  {
    label: 'Welcome Email Sent',
    key: 'welcomeEmailSent',
    cell: 'isActiveTag',
    sortable: false,
    width: 300,
    flexGrow: 0,
  },
];

/**
 * table columns to show if user has not stored value
 * from a previous visit
 */
const defaultKeys = [
  'firstName',
  'lastName',
  'email',
  'welcomeEmailSent',
  'createdOn',
  'type',
  'isActive',
];

export default function useRosieUserTable(
  page: number,
  limit: number,
  sortColumn: string,
  sortType: string,
  search: string,
  userType: string,
  isDone: boolean,
) {
  const [, setSearchParams] = useSearchParams();
  const [data, setData] = useState<RosieUser[]>([]);
  const [isDoneLoading, setIsDoneLoading] = useState(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  // delete
  const [isDoneDeleting, setIsDoneDeleting] = useState(false);
  const [userToDelete, setUserToDelete] = useState('');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  useEffect(() => {
    let mounted = true;

    async function loadDataAsync() {
      try {
        setIsDoneLoading(false);
        const response = await getRosieUsersPagination(
          {
            page,
            limit,
            sortColumn,
            sortType,
            search,
          },
          userType,
        );

        // handle different page
        if (response.page !== page) {
          const params = {
            page: `${response.page}`,
            limit: `${limit}`,
            sortColumn,
            sortType,
          };
          setSearchParams(params);
        }

        // handle page bigger than max
        if (response.totalPages < page) {
          const params = {
            page: `${response.totalPages}`,
            limit: `${limit}`,
            sortColumn,
            sortType,
          };
          setSearchParams(params);
        }
        console.log(response)
        setData(response.docs);
        setTotalCount(response.totalDocs);
        setIsDoneLoading(true);
      } catch (e: any) {
        setIsDoneLoading(true);
        serverErrorHandler(e);
      }
    }

    if (mounted && isDone) loadDataAsync();

    return () => {
      mounted = false;
    };
  }, [page, limit, sortColumn, sortType, search, userType, isDone]);

  // table props
  const [columnKeys, setColumnKeys] = useState<string[]>(defaultKeys);

  const onColumnKeysChange = (keys: string[]) => {
    setColumnKeys(keys);
  };

  // filter columns using the selected columnKeys
  const columns = defaultColumns.filter(column =>
    columnKeys.some(key => key === column.key),
  );

  const closeDialog = () => {
    setShowDeleteDialog(false);
  };

  const deleteUser = async () => {
    try {
      setIsDoneDeleting(false);
      await deleteRosieUser(userToDelete);
      setData(
        data.filter(item => item._id !== userToDelete),
      );
      setIsDoneDeleting(true);
      closeDialog();
      setUserToDelete('');
      openSuccessToaster("User deleted.", 3000);
    } catch (e) {
      setIsDoneDeleting(true);
      serverErrorHandler(e);
    }
  };

  const confirmUserDelete = (id: string) => {
    setUserToDelete(id);
    setShowDeleteDialog(true);
  };

  const handleSendWelcomeEmail = async (id:string) => {
    try {
      await sendWelcomeEmail(id)
      openSuccessToaster("Email Sent.", 3000);
    } catch (e) {
      serverErrorHandler(e);
    }
  };

  return {
    userList: data,
    isDoneLoading,
    defaultColumns,
    columnKeys,
    columns,
    onColumnKeysChange,
    totalCount,
    deleteUser,
    isDoneDeleting,
    setUserToDelete,
    userToDelete,
    confirmUserDelete,
    showDeleteDialog,
    closeDialog,
    handleSendWelcomeEmail
  };
}
