const ALL_STATES = [
    { longName: 'Alabama', shortHand: 'AL' },
    { longName: 'Alaska', shortHand: 'AK' },
    { longName: 'Arizona', shortHand: 'AZ' },
    { longName: 'Arkansas', shortHand: 'AR' },
    { longName: 'California', shortHand: 'CA' },
    { longName: 'Colorado', shortHand: 'CO' },
    { longName: 'Connecticut', shortHand: 'CT' },
    { longName: 'Delaware', shortHand: 'DE' },
    { longName: 'Florida', shortHand: 'FL' },
    { longName: 'Georgia', shortHand: 'GA' },
    { longName: 'Hawaii', shortHand: 'HI' },
    { longName: 'Idaho', shortHand: 'ID' },
    { longName: 'Illinois', shortHand: 'IL' },
    { longName: 'Indiana', shortHand: 'IN' },
    { longName: 'Iowa', shortHand: 'IA' },
    { longName: 'Kansas', shortHand: 'KS' },
    { longName: 'Kentucky', shortHand: 'KY' },
    { longName: 'Louisiana', shortHand: 'LA' },
    { longName: 'Maine', shortHand: 'ME' },
    { longName: 'Maryland', shortHand: 'MD' },
    { longName: 'Massachusetts', shortHand: 'MA' },
    { longName: 'Michigan', shortHand: 'MI' },
    { longName: 'Minnesota', shortHand: 'MN' },
    { longName: 'Mississippi', shortHand: 'MS' },
    { longName: 'Missouri', shortHand: 'MO' },
    { longName: 'Montana', shortHand: 'MT' },
    { longName: 'Nebraska', shortHand: 'NE' },
    { longName: 'Nevada', shortHand: 'NV' },
    { longName: 'New Hampshire', shortHand: 'NH' },
    { longName: 'New Jersey', shortHand: 'NJ' },
    { longName: 'New Mexico', shortHand: 'NM' },
    { longName: 'New York', shortHand: 'NY' },
    { longName: 'North Carolina', shortHand: 'NC' },
    { longName: 'North Dakota', shortHand: 'ND' },
    { longName: 'Ohio', shortHand: 'OH' },
    { longName: 'Oklahoma', shortHand: 'OK' },
    { longName: 'Oregon', shortHand: 'OR' },
    { longName: 'Pennsylvania', shortHand: 'PA' },
    { longName: 'Rhode Island', shortHand: 'RI' },
    { longName: 'South Carolina', shortHand: 'SC' },
    { longName: 'South Dakota', shortHand: 'SD' },
    { longName: 'Tennessee', shortHand: 'TN' },
    { longName: 'Texas', shortHand: 'TX' },
    { longName: 'Utah', shortHand: 'UT' },
    { longName: 'Vermont', shortHand: 'VT' },
    { longName: 'Virginia', shortHand: 'VA' },
    { longName: 'Washington', shortHand: 'WA' },
    { longName: 'West Virginia', shortHand: 'WV' },
    { longName: 'Wisconsin', shortHand: 'WI' },
    { longName: 'Wyoming', shortHand: 'WY' }
  ];


  export default ALL_STATES;