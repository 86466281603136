import React from 'react';
import styled from 'styled-components';
import SclaAppStatusTag from '../tags/SclaAppStatusTag';

interface ISclaAppStatusCellProps {
  rowData: any;
  dataKey: string;
}

const CellContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
function SclaAppStatusCell({ dataKey, rowData }: ISclaAppStatusCellProps) {
  return (
    <CellContainer>
      <SclaAppStatusTag size="md" type={rowData[dataKey]} />
    </CellContainer>
  );
}

export default SclaAppStatusCell;
