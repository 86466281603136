
import React from "react";
import { Button } from "rsuite";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import bg from "../assets/images/404.png";

const Container = styled.div`
  text-align: center;
  height: 100vh;
  background-color: var(--color-white);

  img {
    width: 100%;
    max-width: 250px;
    margin: auto;
    margin-top: 45px;
  }

  h1 {
    font-size: 21px;
    margin-bottom: 15px;
    margin-top: 45px;
  }

  p {
    font-size: 16px;
    padding: 0 25px;
  }

  button {
    margin-top: 35px;
  }

  @media (min-width: 768px) {
    img {
      max-width: 350px;
    }

    h1 {
      font-size: 32px;
      margin-bottom: 15px;
    }

    p {
      font-size: 19px;
      padding: 0 50px;
    }
  }
`;
/**
 * Shows user that the resource they are trying to access
 * no longer exists or has bee moved
 * @returns
 */
function NotFound() {
  const navigate = useNavigate();

  const navigateHome = () => {
    navigate("/");
  };

  return (
    <Container>
      <img src={bg} alt="404" />
      <h1>Looks like you&#39;re lost</h1>
      <p>
        The page you&#39;re trying to reach can&#39;t be found. Perhaps it was a
        bad link.
      </p>

      <Button color="red" onClick={navigateHome}>
        Go Home
      </Button>
    </Container>
  );
}

export default NotFound;
