import DateFormat  from 'enum/dateFormat';
import moment from 'moment';
import 'moment-timezone';

export const MONTH_SHORT = 'MMM D, YYYY';
export const MONTH_LONG = 'MMMM D, YYYY';
export const TIME_SHORT = 'MMM Do YYYY hh:mm A';
export const TIME_SHORT_ALT = 'MM/DD/YY, h:mm a';
export const TIME_HOURS = 'HH:mm A';

/**
 * Pads number with leading zeros. The number of 0s is
 * set by numDigits which represents the number of digits a number
 * should have
 * @param num
 * @param numDigits
 * @returns
 */
export function padLeadingZeros(num: number, numDigits: number) {
  // convert number to string
  let s = `${num}`;

  /**
   * Keep adding leading zeros to the string as long as
   * the string length is less than the number of digits
   * we want
   */
  while (s.length < numDigits) s = `0${s}`;

  return s;
}

/**
 * Returns formatted date string. The date is convert to la timezone
 * @param date
 * @param format
 * @returns
 */
export const momentDateFormat = (date: Date, format: DateFormat): string =>
  moment(date).tz('America/Los_Angeles').format(format);

/**
 * Returns the number of years since date
 * @param date
 * @returns
 */
export const getAgeYears = (date: Date): number =>
  moment().diff(date, 'years');

/**
 * Returns the time from elapsed from the provided date
 *
 * @param date The date object to calc elapsed time from
 * @returns A string with elapsed time from date
 */
export const momentFromNow = (date: Date) => moment(date).fromNow();

/**
 * Returns the provided date in detailed format
 * Sunday, Jan 1, 2021 at 7:30 PM
 * @param date
 * @returns
 */
export const momentDateDetailed = (date: Date) => {
  const part1 = moment(date)
    .tz('America/Los_Angeles')
    .format('dddd, MMM D, YYYY');
  const part2 = moment(date).tz('America/Los_Angeles').format('LT');
  return `${part1} at ${part2}`;
};

/**
 * Returns a string with Hours which are calculated using
 * the provided minutes
 *
 * @param mins The minutes to convest to hours
 * @returns A string with the hours ex. '01:30'
 */
export const momentPaddedHrTime = (mins: number) => {
  // get the minutes that are left over
  const remainder = mins % 60;
  // get the hours
  const hrs = Math.floor(mins / 60);

  return `${padLeadingZeros(hrs, 2)}:${padLeadingZeros(remainder, 2)}`;
};

/**
 * Returns true if the current date is after the provided date
 *
 * @param date The date we want to compare to
 * @returns
 */
export const momentIsAfterDate = (date: Date, hours?: number) => {
  // get comparison date and convert to LA timezone
  const compareDate = moment(date).tz('America/Los_Angeles');

  if (hours) {
    compareDate.add(5, 'hours');
  }

  // return boolen result of check
  return moment().tz('America/Los_Angeles').isAfter(compareDate);
};

export const momentGetCurrentYear = (): string =>
  moment(new Date()).tz('America/Los_Angeles').format('YYYY');
