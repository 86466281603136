import { makeDeleteRequest } from 'lib/axios';
import { RosieUser } from 'features/rosie';

/**
 * Make API request to delete rosie user
 * @returns
 */
const deleteRosieUser = async (id: string): Promise<RosieUser> => {
  const url = `/user/${id}`;
  const response = await makeDeleteRequest(url);
  return response.data.payload;
};

export default deleteRosieUser;
