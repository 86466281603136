import { EnhancedStore } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { ThunkMiddleware } from 'redux-thunk';
import { UserSession } from 'features/rosie';
import { ISideMenuState, toggle } from './slices/sideMenuSlice';
import { IUserSessionState, logout, setUser } from './slices/userSessionSlice';

let store: EnhancedStore<
  {
    currentUser: IUserSessionState;
    sideMenu: ISideMenuState;
  },
  AnyAction,
  [
    ThunkMiddleware<
      {
        currentUser: IUserSessionState;
        sideMenu: ISideMenuState;
      },
      AnyAction,
      undefined
    >,
  ]
>;

export const injectStore = (
  _store: EnhancedStore<
    {
      currentUser: IUserSessionState;
      sideMenu: ISideMenuState;
    },
    AnyAction,
    [
      ThunkMiddleware<
        {
          currentUser: IUserSessionState;
          sideMenu: ISideMenuState;
        },
        AnyAction,
        undefined
      >,
    ]
  >,
) => {
  store = _store;
};

export const dispatchLogout = () => {
  store.dispatch(logout());
};

export const dispatchSideMenuToggle = () => {
  store.dispatch(toggle());
};

export const dispatchSetUser = (user: UserSession) => {
  store.dispatch(setUser(user));
};
