import React from 'react';
import styled from 'styled-components';
import SclaTypeTag from '../tags/SclaTypeTag';

interface ISclaAppTypeCellProps {
  rowData: any;
  dataKey: string;
}

const CellContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
function SclaAppTypeCell({ dataKey, rowData }: ISclaAppTypeCellProps) {
  return (
    <CellContainer>
      <SclaTypeTag size="md" type={rowData[dataKey]} />
    </CellContainer>
  );
}

export default SclaAppTypeCell;
