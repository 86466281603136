import React, { CSSProperties } from "react";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "rsuite";

interface IProps {
  // Added ID for Onboarding
  id?: string;
  size?: "lg" | "md" | "sm" | "xs";
  type: "button" | "submit" | "reset" | undefined;
  appearance: Appearance; // RSUITE button appearance
  color?: Color | undefined; // RSUITE button color - appearance must be 'primary'
  label: string;
  className?: string; // class names for button container
  isLoading: boolean;
  disabled: boolean;
  styles?: CSSProperties; // styles for Button container
  onClick: () => void;
}

/**
 * Renders button with custom label that
 * shows a spinner when a async action is loading
 * @param props
 * @returns
 */
function ButtonSpinner(props: IProps) {
  // Added ID for Onboarding
  const {
    id,
    size = "md",
    color,
    styles,
    type,
    label,
    className,
    isLoading,
    onClick,
    ...rest
  } = props;

  return (
    <Button
      id={id}
      size={size}
      color={color}
      style={styles || {}}
      type={type}
      className={className || ""}
      onClick={onClick}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {isLoading ? (
        <FontAwesomeIcon
          style={{
            paddingLeft: 4,
            paddingRight: 4,
            paddingTop: 1,
            paddingBottom: 1,
          }}
          spin
          icon={faSyncAlt}
        />
      ) : (
        <p>{label}</p>
      )}
    </Button>
  );
}

export default ButtonSpinner;

ButtonSpinner.defaultProps = {
  styles: {},
  className: "",
  color: undefined,
  size: "md",
  id: "",
};