/* eslint-disable react/jsx-props-no-spreading */

import FormControlLabel from 'components/Form/Control/FormControlLabel';
import { FormFieldSize } from 'components/Form/CustomForm';
import React, { forwardRef } from 'react';
import { Form, Input } from 'rsuite';
import styled from 'styled-components';

interface ITextFormField {
  required: boolean;
  flex?: number;
  name: string; // name of the key to map to
  helpMessage?: string; // help message to display
  label: string; // field label
  rows: number; // number of rows to render
  data: any;
}

const FormGroup = styled(Form.Group)<{ ref: any; size: FormFieldSize }>`
  flex: 1 0 100%;
  @media (min-width: 992px) {
    flex: 0 1 ${props => (props.size === 'sm' ? '50%' : '100%')};
  }
`;

const ControlWrapper = styled.div`
  margin: 0.5rem;
`;

const Textarea = forwardRef((props, ref: any) => (
  <Input {...props} as="textarea" ref={ref} />
));

const TextAreaFormField = forwardRef((props: ITextFormField, ref: any) => {
  const { data, flex, name, helpMessage, label, required, ...rest } =
    props;

  return (
    <FormGroup ref={ref} flex={flex}>
      <FormControlLabel required={required || false}>
        {label}
      </FormControlLabel>
      <ControlWrapper>
        <Form.Control
          name={name}
          accepter={Textarea}
          {...rest}
          // @ts-ignore
          rows={data.rows}
        />
        {helpMessage ? <Form.HelpText>{helpMessage}</Form.HelpText> : null}
      </ControlWrapper>
    </FormGroup>
  );
});

export default TextAreaFormField;

TextAreaFormField.defaultProps = {
  helpMessage: undefined,
  flex: undefined,
};
