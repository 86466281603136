import React from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, InputGroup, SelectPicker, Tag, TagPicker } from 'rsuite';
import { DataTableColumn, PickerItem } from 'types';
import HStack from 'components/Layout/HStack';
import styled from 'styled-components';

export type TableActionSearch = {
  value: string;
  onSearch: (s: string) => void;
  label: string;
};

export type TableActionFilter = {
  value: string | null;
  pickerOptions: PickerItem[];
  pickerLabel: string;
  valueLabel: string;
  clearFilters: () => void;
  onFilterChange: (filter: string | null) => void;
};

export type TableActionRecentRegistrationFilter = {
  value: string | null;
  pickerOptions: PickerItem[];
  pickerLabel: string;
  valueLabel: string;
  clearRecentFilters: () => void;
  onRecentFilterChange: (filter: string | null) => void;
};

export type TableActionsProps = {
  search?: TableActionSearch;
  filters?: TableActionFilter[];
  defaultColumns: DataTableColumn[];
  columnKeys: string[];
  onColumnKeysChange: (keys: string[]) => void;
};

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  gap: 16px;
`;

export function TableActions(props: TableActionsProps) {
  const {
    filters,
    search,
    columnKeys,
    defaultColumns,
    onColumnKeysChange,
  } = props;

  const renderSearch = (searchData: TableActionSearch) => (
    <InputGroup style={{ width: '100%', maxWidth: 300 }}>
      <Input
        defaultValue={searchData.value}
        onChange={searchData.onSearch}
        placeholder={searchData.label}
      />
      <InputGroup.Button
        onClick={() => searchData.onSearch(searchData.value)}
      >
        <FontAwesomeIcon icon={faSearch} />
      </InputGroup.Button>
    </InputGroup>
  );

  const renderFilter = (filterData: TableActionFilter[]) => (
    <FilterContainer>
      {filterData.map((filter, i) => (
        <SelectPicker
          key={i}
          onClean={() => filter.clearFilters()}
          value={filter.value}
          style={{ width: '100%', maxWidth: 300 }}
          data={filter.pickerOptions}
          placeholder={filter.pickerLabel}
          onChange={filter.onFilterChange}
          renderMenuItem={label => <div>{label}</div>}
          renderMenuGroup={label => <div>{label}</div>}
          searchable={false}
          cleanable={false}
        />
      ))}
    </FilterContainer>
  );

  return (
    <>
      <HStack marginBottom={15} justifyContent="space-between" gap={20}>
        {search ? renderSearch(search) : <div />}
        {filters ? renderFilter(filters) : <div />}
      </HStack>

      <HStack marginBottom={15} justifyContent="flex-start">
        <TagPicker
          data={defaultColumns}
          labelKey="label"
          valueKey="key"
          value={columnKeys}
          onChange={onColumnKeysChange}
          cleanable={false}
        />
      </HStack>
    </>
  );
}
TableActions.defaultProps = {
  search: undefined,
  filters: undefined,
};

/**
 * Renders the tags selected in the filter picker
 * @param values
 * @param items
 * @returns
 */
export const renderSelectedTags = (value: any, item: any) => (
  <Tag key={value}>{item.label}</Tag>
);
