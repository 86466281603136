import  WindowSize  from 'enums';
import { useEffect, useState } from 'react';

export default function useWindowSize() {
  const [height, setHeight] = useState<number>(window.innerHeight);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [size, setSize] = useState<WindowSize>(WindowSize.MOBILE)

  function handleWindowSizeChange() {
    const w = window.innerWidth;
    const h = window.innerHeight;
    setWidth(w);
    setHeight(h)

    if(w >= 992){
        setSize(WindowSize.DESKTOP)
    }else if (w >= 768 && w < 992){
        setSize(WindowSize.TABLET)
    }else{
        setSize(WindowSize.MOBILE)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return {
    isMobile,
    size,
    height,
    width
  }
}
