/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import { AutoComplete, Form } from 'rsuite';
import styled from 'styled-components';
import { FormFieldSize } from 'components/Form/CustomForm';
import FormControlLabel from '../../Control/FormControlLabel';

interface ITextFormField {
  required: boolean;
  formValue: any;
  onChange: (val: any) => void;
  data: any[];
  size: FormFieldSize;
  disabled?: boolean;
  name: string; // name of the key to map to
  label: string; // field label
  type: 'text' | 'email' | 'number' | 'password';
  helpMessage?: string; // help message to display
  placeholder?: string; // field placeholder
  checkAsync?: boolean; // true if we need to perform async checks on form field
  className?: any; // custom class name for form field group
}

const FormGroup = styled(Form.Group)<{ ref: any; size: FormFieldSize }>`
    flex: 1 0 100%;
  @media (min-width: 992px){
    flex: 0 1 ${props => (props.size === 'sm' ? '50%' : '100%')};
  }
`;

const ControlWrapper = styled.div`
  margin: 0.5rem;
`;

const AutoCompleteItem = styled.div`
  width: 200px;
  overflow: hidden;
  margin: 3px 0px;
`;
/**
 * Renders text from field
 */
const AutoCompleteFormField = forwardRef(
  (props: ITextFormField, ref: any) => {
    const {
      name,
      size,
      formValue,
      onChange,
      helpMessage,
      label,
      required,
      data,
    } = props;

    return (
      <FormGroup ref={ref} size={size}>
          <FormControlLabel required={required || false}>{label} </FormControlLabel>
        <ControlWrapper>
          {/* <Form.Control
            disabled={disabled || false}
            // placeholder={placeholder}
            name={name}
            acceptor={AutoComplete}
            data={data}
          /> */}
          <AutoComplete
            value={formValue[name]}
            onChange={onChange}
            data={data}
            style={{ width: '100%' }}
            renderMenuItem={item => (
              <AutoCompleteItem>
                <p>{item}</p>
              </AutoCompleteItem>
            )}
          />
          {helpMessage ? (
            <Form.HelpText>{helpMessage}</Form.HelpText>
          ) : null}
        </ControlWrapper>
      </FormGroup>
    );
  },
);

export default AutoCompleteFormField;

AutoCompleteFormField.defaultProps = {
  disabled: undefined,
  helpMessage: undefined,
  placeholder: undefined,
  checkAsync: undefined,
  className: undefined,
};
