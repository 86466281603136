import { MY_TXT_USER_TYPE } from './myTxt.constants';
import { ROSIE_USER_TYPE } from './rosie';
import SCHOOL_LIST from './schools';
import { SCLA_APP_STATUS, SCLA_APP_TYPE, SCLA_PROGRAM_YEAR } from './scla.constants';
import { SEALS_CREED } from './seals.constants';
import ALL_STATES from './states';
import { TXTER_USER_TYPE } from './txter.constants';

export const PICKER_ROSIE_USER_TYPE = ROSIE_USER_TYPE.map(item => ({
  ...item,
  role: '',
}));

export const PICKER_SCLA_APP_TYPE = SCLA_APP_TYPE.map(item => ({
  ...item,
  role: '',
}));


export const PICKER_ALL_STATES = ALL_STATES.map(state => ({
  label: state.longName,
  value: state.longName.toLocaleLowerCase(),
  role: '',
}));

export const PICKER_SCHOOLS = SCHOOL_LIST.map(school => ({
  label: school,
  value: school,
  role: '',
}));

export const PICKER_TXTER_USER_TYPE = TXTER_USER_TYPE.map(item => ({
  ...item,
  role: '',
}));

export const PICKER_SCLA_APP_STATUS = SCLA_APP_STATUS.map(item => ({
  ...item,
  role: '',
}));

export const PICKER_SCLA_PROGRAM_YEAR = SCLA_PROGRAM_YEAR.map(item => ({
  ...item,
  role: '',
}));

export const PICKER_SEALS_CREED = SEALS_CREED.map(item => ({
  ...item,
  role: '',
}));

export const PICKER_MY_TXT_USER_TYPE = MY_TXT_USER_TYPE.map(item => ({
  ...item,
  role: '',
}));

export const PICKER_YES_NO = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
];

export const PICKER_ETHNICITY = [
  {
    value: 'African American',
    label: 'African American',
  },
  {
    value: 'Latino/Hispanic',
    label: 'Latino/Hispanic',
  },
  {
    value: 'Asian',
    label: 'Asian',
  },
  {
    value: 'Caucasian',
    label: 'Caucasian',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const PICKER_SCLA_HEAR_ABOUT_US = [
  {
    label: 'Recommended by TXT Student',
    value: 'Recommended by TXT Student',
  },

  {
    label: 'Social Media',
    value: 'Social Media',
  },

  {
    label: 'TXT Website',
    value: 'TXT Website',
  },

  {
    label: 'School Presentation',
    value: 'School Presentation',
  },

  {
    label: 'Other',
    value: 'other',
  },
];

export const PICKER_SCLA_TRANSPORTATION = [
  {
    label: 'Car',
    value: 'car',
  },

  {
    label: 'Bus',
    value: 'bus',
  },

  {
    label: 'Train',
    value: 'train',
  },

  {
    label: 'Walk',
    value: 'walk',
  },
];

export const PICKER_LANGUAGES = [
  { label: 'English', value: 'english', role: '' },
  { label: 'Spanish', value: 'spanish', role: '' },
  { label: 'Mandarin Chinese', value: 'mandarin-chinese', role: '' },
  { label: 'Korean', value: 'korean', role: '' },
  { label: 'Japanese', value: 'japanese', role: '' },
  { label: 'Other', value: 'other', role: '' },
];

export const PICKER_SCHOOL = [
  { label: 'All Schools', value: 'all' },
  ...SCHOOL_LIST.map(s => ({ label: s, value: s })),
];

export const PICKER_GRADE = [
  {
    value: '8',
    label: '8th Grade',
    role: 'Grade',
  },
  {
    value: '9',
    label: '9th Grade',
    role: 'Grade',
  },
  {
    value: '10',
    label: '10th Grade',
    role: 'Grade',
  },
  {
    value: '11',
    label: '11th Grade',
    role: 'Grade',
  },
  {
    value: '12',
    label: '12th Grade',
    role: 'Grade',
  },
];
