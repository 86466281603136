import React from 'react';
import ContentContainer from 'components/Layout/ContentContainer';
import CustomForm from 'components/Form/CustomForm';
import useUpdateRosieUser from '../hooks/useUpdateRosieUser';
import { RosieUser } from '../types';

interface IUpdateRosieUserFormProps {
  defaultValue: RosieUser;
  onUpdate: (value: RosieUser) => void;
}
export default function UpdateRosieUserForm(
  props: IUpdateRosieUserFormProps,
) {
  const { defaultValue, onUpdate } = props;
  
  const {
    value,
    isDisabled,
    fields,
    isLoading,
    model,
    hasChanges,
    onChange,
    onSubmit,
    onCancel,
  } = useUpdateRosieUser(defaultValue, onUpdate);

  return (
    <ContentContainer title={defaultValue.name}>
      <CustomForm
        hasChanges={hasChanges}
        fields={fields}
        isLoading={isLoading}
        model={model}
        formValue={value}
        onChange={onChange}
        onSubmit={onSubmit}
        onCancel={onCancel}
        isDisabled={isDisabled}
        submitLabel="Save Changes"
      />
    </ContentContainer>
  );
}
