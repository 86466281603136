import React, { Suspense } from 'react';
import lazyImport from 'utils/lazyImport';
import  Loader  from 'components/Elements/Loader/Loader';

//
const { AuthRoutes } = lazyImport(
  () => import('features/auth'),
  'AuthRoutes',
);

const publicRoutes = [
  {
    path: '/*',
    element: (
      <Suspense fallback={<Loader theme="dark" isVisible fullscreen />}>
        <AuthRoutes />
      </Suspense>
    ),
  },
];

export default publicRoutes;
