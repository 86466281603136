import React from 'react';
import { Whisper, Tooltip as RTooltip } from 'rsuite';

type TooltipProps = {
  className?: string;
  msg: string; // the message to show in the tooltip
  children: any; // the content to render as the trigger for the tooltip
  placement:
    | 'top'
    | 'topStart'
    | 'topEnd'
    | 'leftStart'
    | 'rightStart'
    | 'left'
    | 'right'
    | 'leftEnd'
    | 'rightEnd'
    | 'bottomStart'
    | 'bottom'
    | 'bottomEnd'; // placement for tooltip
};

export default function Tooltip({
  msg,
  children,
  placement,
  className,
}: TooltipProps) {
  const tooltip = <RTooltip>{msg}</RTooltip>;
  return (
    <div className={className}>
      <Whisper
        placement={placement}
        controlId="tool-tip-hover"
        trigger="hover"
        speaker={tooltip}
      >
        <div>{children}</div>
      </Whisper>
    </div>
  );
}

Tooltip.defaultProps = {
  className: '',
};
