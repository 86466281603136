import React from 'react';
import NavBar from 'components/Navigation/NavBar';
import SideNav from 'components/Navigation/SideNav';
import { useAppSelector } from 'store/hooks';
import { getSideMenuStatus } from 'store/slices/sideMenuSlice';
import styled from 'styled-components';
import { UserSession } from 'features/rosie';

export type IMenuWrapperProps = {
  children: React.ReactNode;
  user: UserSession;
};

const Container = styled.div<{ isOpen: boolean }>`
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;
  background-color: #edf1f7;
  padding-top: 76px;
  display: flex;
  flex-direction: column;
  padding-left: 0px;

  @media (min-width: 992px) {
    padding-left: ${props => (props.isOpen ? '256px' : '0px')};
  }
`;

/**
 * Renders content within a navigation menu and side menu.
 * The navigation menu contains user profile info. Side menu
 * contains page links. This menu can opened and closed.
 * @param props
 * @returns
 */
export function MainLayout({ children, user }: IMenuWrapperProps) {
  // if true, side menu is open
  const sideMenuIsOpen = useAppSelector(getSideMenuStatus);

  return (
    <div>
      <NavBar user={user} />
      <SideNav />
      <Container isOpen={sideMenuIsOpen}>{children}</Container>
    </div>
  );
}
